body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}
html, body {
    /*height: 100%;*/
    margin: 0;
    font-family: Montserrat, SansSerif;
}

.App {
    height: 100%;
    text-align: center;
    margin: auto;
    background-color: #e9ecef;
    max-width: 500px;
}

#root {
    height: 100%;
}

.profilePicLarge {
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profilePicXSmall {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.notificationRow {
    border-bottom: 2px solid #eaebf1;
}

.newNotification {
    background-color: #a3cfbb;
}

.readedNotification {
    background-color: #f8f9fa;
}

.conversationContainer {
    background: #fff5ed;
}

.newMsg {
    background-color: #a3cfbb;
}

.readedMsg {
    background-color: #fff5ed;
}

.displayNone {
    display: none;
}

.profilePicMedium {
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.uploadBrowseButton {
    padding: .5rem 1.5rem;
    border-radius: 5px;
    color: white;
    background: #087990;
}

.uploadBrowseButton:hover {
    color: white;
    background: #0dcaf0;
}

.clear-both {
    clear: both;
}

.nav-link a {
    color: white;
}


.userFullName {
    color: #563918;
    text-decoration: none;
}

.userFullName:hover {
    text-decoration: none;
    color: #282c34
}

.userFullNameLight {
    color: #fae6d3;
    text-decoration: none;
}

.userFullNameLight:hover {
    text-decoration: none;
    color: #b9ccf1
}

.reviewTypeActivity {
    font-style: italic;
    color: #504a4a;
}

.activeTitle {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.color-white {
    color: white;
}

.registerOuter {
    min-height: 100%;
    width: 100%;
    position: absolute;
    margin-bottom: -50px;
    background-image: url(/img/register_bg.jpeg);
    background-size: cover;
}

.passiveTitle {
    color: #a9a5a5;
    font-weight: bold;
    cursor: pointer;
}

.paymentIcons {
    font-size: 25px;
    color: #4397f9;
}

.landingBottom {
    height: 200px;
}

.breakLine {
    white-space: pre-line;
}

.completeProfileLink {
    height: 25px;
    background: #cde6c1;
    margin-bottom: 3px;
}

.howitworks1 {
    background: #1baebb;
    color: white;
    font-weight: 600;
    padding: 10px;
}

.howitworks2 {
    color: white;
    background-color: #4f466d;
    padding: 10px;
    font-weight: 600;
}

.btnJoin {
    background-color: #4f466d;
    color: white;
}

.landing_parallax {
    /* The image used */
    background-image: url("/img/landing_fixed_middle.jpg");

    /* Set a specific height */
    height: 250px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.activeTitle:hover, .passiveTitle:hover {
    color: black;
}

.landingSlide {
    width: 99vw;
    height: 100vh;
    background-image: url(/img/landing_slide.jpg);
    background-size: cover;
}

.landingTitleCounter {
    font-size: 2em;
}

.full-width {
    width: 100%;
}

.landingSlideTitle {
    color: #51456c;
    font-weight: 600;
    width: 325px;
    border: 1px solid red;
    padding: 9px;
    border-radius: 9px;

}

.landingTitle {
    margin-top: 25px;
    color: #a68375;
    font-weight: 600;
}

.landingOuter {
    width: 100%;
    min-height: 950px;
    background-color: #eff5f5;
}

.landingText {
    color: #646f7f;
    font-weight: 600;
}

.landingTextTitle {
    color: #6d730e;
}

.landingIcons {
    font-size: 2em;
    color: #a68375;
}

.landingFeatures {
    background: white;
    padding-top: 25px;
}

.lightOnGreen {
    color: #eff70a;
}

.bg-menu {
    background: linear-gradient(45deg, #00c4cc, #534269);
    padding-top: 0px;
    padding-bottom: 0;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.meetingFormPhoto {
    width: 100%;
    margin-bottom: 15px;
    max-height: 400px;
}


.incomingMessage, .outgoingMessage, .ghostMessage {
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.ghostMessage {
    padding: 1px 1px 3px;
    float: left;
}

.ghostMessageContainer {
    clear: both;
    text-align: left;
}

.messageDate {
    color: rgb(220, 53, 69);
    font-size: 14px;
    font-weight: 600;
    text-align: right;
}

.incomingMessage {
    background: #b3b7e6;
    padding: 9px 9px 3px;
    float: left;

}

.outgoingMessage {
    background: #85e847;
    padding: 9px 9px 3px;
    float: right;

}

.profileButton {
    margin-bottom: 4px;
    width: 90%;
    font-size: 0.8rem;
}


.half-left {
    float: left;
    width: 48%;
}

.triple-left {
    float: left;
    width: 31%;
}

.meetingListPhoto {
    width: 98%;
    object-fit: cover;
    max-height: 470px;
}

.city-filter-label {
    margin-top: 7px
}

.container {
    background: white;
    padding-top: 15px;
    padding-bottom: 50px;
}

.outer {
    background: #eaebf1;
    min-height: 100vh;
    width: 100%;
    margin-bottom: -50px;

}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.searchItemContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d8c8c8;
}

.customRadio {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    border: 4px solid #ccc;
    border-top-color: #bbb;
    border-left-color: #bbb;
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    vertical-align: middle;
}

.customRadio:checked {
    border: 10px solid #4099ff;
}

.silverCheck {
    color: gray;
    font-size: 16px;
}

.goldCheck {
    color: orange;
    font-size: 16px;
}

.proCheck {
    color: blue;
    font-size: 16px;
}

.complainOnMessagePage {
    float: right;
    font-size: 0.9rem;
}

.adminOuter {
    background: white;
    min-height: 100%;
    width: 100%;
    margin-bottom: -50px;
}

.profilePicSmallMobile {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profilePicSmall {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}


.policeButtonDivMobile {
    padding: 15px;
}

.notificationTextMobile {
    padding-top: 0px;
    margin-left: 53px;
}

.profileMetaMobile {
    width: 200px;
    margin-top: 25px;
    height: 115px;
}

.conversationTextMobile {
    background: #ffffff;
    padding: 10px;
    color: black;
    border-radius: 10px;
    width: 100%;
    text-decoration: none;
}

.conversationTextMobile:hover {
    text-decoration: none;
}

.profileTitleMobile {
    color: #fd7e14;
    font-size: 1rem;
    text-decoration: none;
}

.profilePhotoReviewActivity {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 700;
    text-decoration: none;
}

.profileTitleMobile:hover {
    text-decoration: none;
    color: blue;
}

.profileTitleMobileDiv {
    border-radius: 4px;
    background-color: #198754;
}

.activityMessageMobile {
    float: left;
}

.activityMessageMobileWarning {
    color: #B68C8C;
    font-size: 13px;
}

.my-interestsMobile {
    font-size: 13px;
    margin: 5px;
}

.messageTextAreaMobile {
    width: 77%;
    font-size: 13px;
}

.sendMessageButton {
    width: 22%;
    float: right;
    margin-top: -45px;
}

.loadingMobile {
    height: 1000px;
    color: white;
    background-color: gray;
}

.scrollTopMobile {
    position: fixed;
    bottom: 70px;
}

.scrollTopArrow {
    font-size: 40px;
    color: #fe8d44;
}

.bottomNavigation {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 9999;
}

.navbarContainer {
    position: relative;
}

.hamburgerContainerMobile {
    display: inline-block;
    overflow: hidden;
    background-color: #fd7e14;
    position: absolute;
    bottom: 54px;
    height: 132px;
    width: 50%;
    right: 0;
    border-radius: 6px;
}

.hamburgerMenuItemMobile {
    width: 100%;
    border: 1px solid #ffffff;
    height: 44px;
    font-size: 1.2rem;
    padding: .5rem;
}

.hamburgerMenuItemMobile a {
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.navbarMobile {
    overflow: hidden;
    background-color: #fd7e14;
    width: 100%;
    max-width: 500px;
    border-top: 1px solid #ca6510;
    box-shadow: 0 -5px 3px -3px rgba(253, 126, 20, .3);
}

.albumPhotoMobile {
    width: 99%;
}

.navbarMobile a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: .5rem .75rem;
    text-decoration: none;
    font-size: 18px;
}

.timeSelectMobile {
    width: 81px;
}

.timeSelectLabelMobile {
    margin-top: 10px;
}

.navbarMobile a:hover {
    background: #ca6510;
}

.messageBoxMobile {
    width: 100%;
    background: #f5f0e8;
    border-radius: 5px;
    height: 450px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.ghostProfilePic {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%
}

.ghostMessageBoxMobile {
    width: 100%;
    border-radius: 5px;
    height: 490px;
    background-color: #f3f7f0;
    overflow-y: scroll;
}

.fontMobile {
    font-size: 0.8rem;
}

.navbarMobile a.active {
    background-color: #084298;
    color: white;
}

.menuIconMobile {
    font-size: 25px;
}

/*.mainMobile {*/
/*padding: 16px;*/
/*margin-bottom: 30px;*/
/*}*/

.category-button-active {
    background-color: #198754;
}

.category-button-passive {
    background-color: #fecba1;
}

.category-button-passive > span {
    color: black;
}

.category-button {
    border-radius: 3px;
    margin: 2px;
    padding: 1px 0px;
    font-weight: 600;
    color: white;
}

/*.profileActionsMobile{*/
/*padding: 5px;*/
/*}*/

.error-message {
    color: red;
}

.loginContainerMobile {
    padding: 15px;
    height: 300px;
    opacity: 0.9;
}

.btn-menuColorMobile {
    background-color: #feb272;
}

.registerContainerMobile {
    padding: 15px;
    height: 100vh;
    background-color: #545f7d;
}

.registerCompletedMessage {
    border-radius: 4px;
    background-color: white;
    padding: 5px;
}

.registerBody {
    background: #233a48;
}

.userTermsMobile {
    width: 80%;
    margin-left: 10%;
    font-size: 0.7rem;
}

.landing_parallax_textMobile {
    padding: 40px;
    background-color: #2b292a;
    color: white;
    height: 100%;
    font-size: 2em;
    opacity: 0.7;
    text-align: center;
}

.selectedActivity {
    background-color: #F4D03F;
}

.waitingApprovalMobile {
    background-color: red;
}

.landingLoginRegisterMobile {
    width: 90%;
}

input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
}

.shareOnWhatsapp {
    padding: 7px;
    background: orange;
    border-radius: 5px;
    color: white;
}

.landingContainerMobile {
    width: 100%;
    background-color: white;
    /* padding: 5px;*/
}

.messageWarning {
    font-size: 0.7rem;
    color: #615757;
}

.sponsorTextMobile {
    text-decoration: none;
    color: black;
}

.bottom {
    bottom: 25px;
    position: absolute;
}

.activityListActivityDetailMobile {
    white-space: pre-line;
}

.loginOuterMobile {
    background-image: url(/img/login_mobile.png);
    background-size: cover;
    min-height: 100%;
    position: absolute;
    width: 100%;
    margin-bottom: -50px;

}

.reviewBlockMobile {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c1c2ca;
    width: 80%;
    text-align: left;
    margin-left: 5px;
    margin-top: 5px;
}

.customRadioLabelMobile {
    font-size: 1rem;
    margin: 0;
}

.pandemiContainerMobile {
    background-color: #594186;
    overflow: hidden;
    border-radius: 9px;
}

.pandemiPersonMobile {
    float: left;
    position: absolute;
    border-radius: 50%;
}

.healthyPersonMobile {
    background-color: white;
}

.sickPersonMobile {
    background-color: black;
    border: 4px dashed #594186;

}

.doctorPersonMobile {
    background-color: #6ca71e;
}

/* Hamburger Animation */

#nav-icon3 {
    width: 36px;
    height: 24px;
    position: relative;
    float: right;
    margin: .6rem .75rem;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
    top: 0px;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 12px;
}

#nav-icon3 span:nth-child(4) {
    top: 24px;
}

#nav-icon3.open span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 12px;
    width: 0%;
    left: 50%;
}

.orange-card.card {
    box-shadow: 0 .5rem 1rem rgba(255, 229, 224, .5) !important;
}

.orange-shadow {
    box-shadow: 0 .5rem 1rem rgba(255, 229, 224, .5) !important;
}

.orange-card.card > .card-header {
    background-color: #ffe5d0 !important;
}

.full-calendar > .rdtPicker {
    width: 100%;
}

.filter-navbar {
    --bs-bg-opacity: 1;
    background-color: #fd7e14 !important;
}

/* Coded with love by Mutiullah Samim */
.user_card {
    height: 400px;
    width: 350px;
    margin-top: auto;
    margin-bottom: auto;
    background: #fd7e14;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.register_card {
    width: 350px;
    background: #fd7e14;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #ffe5d0;
    padding: 10px;
    text-align: center;
}

.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}

.register_logo_container {
    position: absolute;
    height: 85px;
    width: 85px;
    top: -40px;
    border-radius: 50%;
    background: #ffe5d0;
    padding: 5px;
    text-align: center;
}

.register_logo {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: 2px solid white;
}

.gender_label {
    width: 100%;
    flex: 1 1;
}

.search_label {
    width: 100%;
    flex: 1 1;
}

.form_container {
    margin-top: 100px;
}

.login_btn {
    width: 100%;
    background: #0d6efd !important;
    color: white !important;
}

.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.login_container {
    padding: 0 2rem;
}

.input-group-text {
    background: #0d6efd !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #0d6efd !important;
}

.heart-button:active,
.heart-button:focus,
.heart-button:hover {
    color: #de3545 !important;
    border-color: #de3545 !important;
    background-color: #ffffff !important;
}

.imessage {
    background-color: #fff;
    border: 1px solid #e5e5ea;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    font-family: "SanFrancisco";
    font-size: 1rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.5rem 0.5rem;
    max-height: 65vh;
    overflow-y: scroll;
}

.imessage p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 85%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
}

p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff;
    font-family: system-ui;
}

p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"] {
    margin: 0.5rem 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
}

p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
    font-family: system-ui
}

p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
    content: none;
}

.no-tail::before {
    display: none;
}

.margin-b_none {
    margin-bottom: 0 !important;
}

.margin-b_one {
    margin-bottom: 1rem !important;
}

.margin-t_one {
    margin-top: 1rem !important;
}

.navbar {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.messageNavbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-5 {
    border-radius: 0.5rem !important;
}

.daterangepicker .drp-calendar.right {
    display: none;
}

.profileEditLabel {
    font-size: 1.25rem;
    font-weight: 600;
}

.profileEditTextArea {
    background-color: #e9ecef;
    color: black;
    width: 95%;
    margin: auto;
    border: 0 none;
    border-bottom: 2px solid #f97e14;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 500;
}

.profileEditTextArea:disabled {
    opacity: .4;
}

.profileEditSelect > div {
    background-color: #e9ecef;
    color: black;
    margin: auto;
    border: 0 none;
    border-bottom: 2px solid #f97e14;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.imageDeleteButton {
    position: relative;
    top: -3rem;
    height: 3rem;
    width: 3rem;
}

.myGroupMessagePhoto {
    position: absolute;
    right: -55px;
    bottom: -5px;
    z-index: 10;
}

.themGroupMessagePhoto {
    position: absolute;
    left: -55px;
    bottom: -5px;
    z-index: 10;
}

.groupMessage {
    max-width: 75% !important;
}

.groupMessage.myGroupMessage {
    margin-right: 55px !important;
}

.groupMessage.themGroupMessage {
    margin-left: 55px !important;
}

@media screen and (max-width: 356px) {
    .navbarMobile i {
        height: 20px;
        width: 20px;
    }

    .menuIconMobile {
        font-size: 20px;
    }
}

@media screen and (max-width: 324px) {
    .navbarMobile i {
        height: 18px;
        width: 18px;
    }

    .menuIconMobile {
        font-size: 18px;
    }

    #nav-icon3 {
        height: 22px;
        width: 22px;
        margin: .5rem .75rem;
    }
}

@media screen and (max-width: 298px) {
    .navbarMobile i {
        height: 16px;
        width: 16px;
    }

    .menuIconMobile {
        font-size: 16px;
    }

    #nav-icon3 {
        height: 20px;
        width: 20px;
        margin: .5rem .75rem;
    }
}

@media screen and (max-width: 288px) {
    .navbarMobile i {
        height: 14px;
        width: 14px;
    }

    .menuIconMobile {
        font-size: 14px;
    }

    #nav-icon3 {
        height: 22px;
        width: 22px;
        margin: .5rem .75rem;
    }
}


@media screen and (max-width: 274px) {
    .navbarMobile a {
        padding: .25rem .5rem;
    }

    .navbarMobile i {
        height: 14px;
        width: 14px;
    }

    .menuIconMobile {
        font-size: 10px;
    }

    #nav-icon3 {
        height: 18px;
        width: 18px;
        margin: .25rem .5rem;
    }

    #nav-icon3 span {
        height: 3px;
    }
}
